import { collection, addDoc, getDocs, query, where, Timestamp, setDoc, doc } from 'firebase/firestore';
import { db } from './firebase';
import type { OfferData, StoredOffer, UserProfile } from './types';

export const createUserProfile = async (userId: string, email: string): Promise<void> => {
  try {
    const userProfile: Omit<UserProfile, 'id'> = {
      email,
      createdAt: new Date(),
      updatedAt: new Date()
    };

    await setDoc(doc(db, 'users', userId), {
      ...userProfile,
      userId
    });
  } catch (error) {
    console.error('Error creating user profile:', error);
    throw error;
  }
};

export const storeOffer = async (offerData: OfferData, userId: string): Promise<void> => {
  try {
    const storedOffer: Omit<StoredOffer, 'id'> = {
      advertiserName: offerData.advertiserName,
      email: offerData.email,
      amount: offerData.amount,
      currency: offerData.currency,
      description: offerData.description,
      userId,
      status: 'pending',
      createdAt: new Date()
    };

    await addDoc(collection(db, 'offers'), storedOffer);
  } catch (error) {
    console.error('Error storing offer:', error);
    throw error;
  }
};

export const getOffers = async (userId: string): Promise<StoredOffer[]> => {
  try {
    const q = query(collection(db, 'offers'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as StoredOffer[];
  } catch (error) {
    console.error('Error fetching offers:', error);
    throw error;
  }
};