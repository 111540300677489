import React, { useState } from 'react';
import { X } from 'lucide-react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useAuth } from './auth/AuthContext';
import { storeOffer } from '../lib/database';
import { sendOfferNotification } from '../lib/email';
import type { OfferData } from '../lib/types';

interface OfferModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: OfferData) => void;
}

const OfferModal: React.FC<OfferModalProps> = ({ isOpen, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    advertiserName: '',
    email: '',
    amount: '',
    currency: 'USD',
    description: ''
  });
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const { user } = useAuth();

  if (!isOpen) return null;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!recaptchaToken) {
      alert('Please complete the CAPTCHA verification');
      return;
    }

    if (!user) {
      alert('Please sign in to submit an offer');
      return;
    }

    try {
      const offerData: OfferData = {
        ...formData,
        amount: Number(formData.amount),
        recaptchaToken,
      };

      // Store in Firebase
      await storeOffer(offerData, user.uid);

      // Send email notification
      await sendOfferNotification(offerData, user.uid);

      // Call the original onSubmit handler
      onSubmit(offerData);

      // Reset form
      setFormData({ advertiserName: '', email: '', amount: '', currency: 'USD', description: '' });
      setRecaptchaToken('');
      onClose();
    } catch (error) {
      console.error('Error submitting offer:', error);
      alert('Failed to submit offer. Please try again.');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl w-full max-w-md">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-xl font-semibold">Submit an Offer</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Company/Advertiser Name
            </label>
            <input
              type="text"
              value={formData.advertiserName}
              onChange={(e) => setFormData({ ...formData, advertiserName: e.target.value })}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-gray-200"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Email
            </label>
            <input
              type="email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-gray-200"
              required
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Amount
              </label>
              <input
                type="number"
                value={formData.amount}
                onChange={(e) => setFormData({ ...formData, amount: e.target.value })}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-gray-200"
                required
                min="0"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Currency
              </label>
              <select
                value={formData.currency}
                onChange={(e) => setFormData({ ...formData, currency: e.target.value })}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-gray-200"
              >
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
                <option value="GBP">GBP</option>
              </select>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Description
            </label>
            <textarea
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-gray-200"
              rows={4}
              required
            />
          </div>

          <div className="flex justify-center">
            <ReCAPTCHA
              sitekey="6LcqgGcpAAAAAFR_tCA_Zz2g63UjZCZGEGY-O8g7"
              onChange={(token) => setRecaptchaToken(token || '')}
            />
          </div>

          <div className="flex gap-3 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="flex-1 px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="flex-1 px-4 py-2 bg-gray-900 text-white rounded-lg hover:bg-gray-800 transition-colors"
            >
              Submit Offer
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OfferModal;