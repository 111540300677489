import React from 'react';
import type { ButtonCustomization } from '../lib/types';

interface OfferButtonProps {
  onClick: () => void;
  customization?: ButtonCustomization;
}

const defaultStyles: ButtonCustomization = {
  text: 'Partner with Us',
  backgroundColor: '#FFFFFF',
  textColor: '#000000',
  showIcon: false,
  position: 'bottom-right',
  fontSize: '0.875rem',
  padding: '0.75rem 1.5rem',
  borderRadius: '0.25rem',
  borderColor: '#E5E7EB',
};

const getPositionClasses = (position: ButtonCustomization['position']) => {
  switch (position) {
    case 'bottom-left':
      return 'bottom-4 left-4';
    case 'top-right':
      return 'top-4 right-4';
    case 'top-left':
      return 'top-4 left-4';
    default:
      return 'bottom-4 right-4';
  }
};

const OfferButton: React.FC<OfferButtonProps> = ({ onClick, customization = {} }) => {
  const styles = { ...defaultStyles, ...customization };
  const positionClasses = getPositionClasses(styles.position);

  return (
    <button
      onClick={onClick}
      className={`fixed ${positionClasses} shadow-sm hover:shadow-md transition-all duration-300 border`}
      style={{
        backgroundColor: styles.backgroundColor,
        color: styles.textColor,
        fontSize: styles.fontSize,
        padding: styles.padding,
        borderRadius: styles.borderRadius,
        borderColor: styles.borderColor,
      }}
    >
      {styles.text}
    </button>
  );
};

export default OfferButton;