import React, { useState } from 'react';
import OfferButton from './components/OfferButton';
import OfferModal from './components/OfferModal';
import type { OfferData, WidgetOptions } from './lib/types';

export const AdSpotterWidget: React.FC<WidgetOptions> = ({
  onOfferSubmit,
  apiEndpoint = 'https://api.adspotter.com/offers',
  buttonStyle
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = async (data: OfferData) => {
    try {
      // If custom handler provided, use it
      if (onOfferSubmit) {
        await onOfferSubmit(data);
        setIsModalOpen(false);
        return;
      }

      // Otherwise use default API endpoint
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Failed to submit offer');
      }

      alert('Offer submitted successfully!');
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error submitting offer:', error);
      alert('Failed to submit offer. Please try again.');
    }
  };

  return (
    <>
      <OfferButton
        onClick={() => setIsModalOpen(true)}
        customization={buttonStyle}
      />
      <OfferModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default AdSpotterWidget;