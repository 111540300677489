import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from './firebase';

export const sendOfferNotification = async (offerData: any, userId: string) => {
  try {
    const functions = getFunctions(app);
    const sendEmail = httpsCallable(functions, 'sendOfferNotification');
    await sendEmail({ offerData, userId });
  } catch (error) {
    console.error('Error sending email notification:', error);
    throw error;
  }
};